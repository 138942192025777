<template>
  <div class="select-time-picker visible">
    <ul class="list-of-time-slots list-of-minutes-wrapper">
      <li class="hours-placeholder">
        <icon icon="#cx-app1-minutes-selection" width="25" height="25" />
      </li>
      <section v-if="selectedHours[0]" class="list-of-minutes">
        <li
          class="select-time-button-wrapper"
          v-for="minute in minuteSlotsFirst"
          :key="minute"
        >
          <button
            data-skip
            class="select-time-btn"
            :class="{
              'selected first': selectedHours[0] === minute,
              small: displayTime(minute).length > 5
            }"
            @click="selectMinute(minute, true)"
            type="button"
          >
            {{ displayTime(minute) }}
          </button>
        </li>
      </section>
    </ul>
    <ul class="list-of-time-slots list-of-hours">
      <li
        class="select-time-button-wrapper"
        v-for="hour in hourSlots"
        :key="hour"
      >
        <button
          data-skip
          class="select-time-btn"
          :class="{
            selected: isSelected(hour) || isInRange(hour),
            first: isStartOfTheRange(hour),
            last: isEndOfTheRange(hour),
            small: displayTime(hour).length > 5,
            'no-range': !rangeMarked
          }"
          @click="
            activeTab = 2;
            selectHour(hour);
          "
          type="button"
        >
          {{ displayTime(hour) }}
        </button>
      </li>
    </ul>
    <ul class="list-of-time-slots list-of-minutes-wrapper second">
      <li class="hours-placeholder second">
        <icon icon="#cx-app1-minutes-selection" width="25" height="25" />
      </li>
      <section v-if="selectedHours[1]" class="list-of-minutes">
        <li
          class="select-time-button-wrapper"
          v-for="minute in minuteSlotsSecond"
          :key="minute"
        >
          <button
            data-skip
            class="select-time-btn"
            :class="{
              'selected first': selectedHours[1] === minute,
              small: displayTime(minute).length > 5
            }"
            @click="selectMinute(minute, false)"
            type="button"
          >
            {{ displayTime(minute) }}
          </button>
        </li>
      </section>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "SelectTimePickerRange",
  data() {
    return {
      firstHourSelected: false
    };
  },
  computed: {
    ...mapGetters("settings", ["globalTimeFormat"]),
    hourSlots() {
      const hours = 23;
      const hourSlots = [];
      for (let i = 0; i <= hours; i++) {
        hourSlots.push(String(i).length < 2 ? `0${i}:00` : `${String(i)}:00`);
      }
      return hourSlots;
    },
    minuteSlotsFirst() {
      const selectedHour = this.selectedHours[0].split(":")[0];
      const minutes = 55;
      const minuteSlots = [];
      for (let i = 0; i <= minutes; i += 5) {
        minuteSlots.push(
          String(i).length < 2
            ? `${selectedHour}:0${i}`
            : `${selectedHour}:${String(i)}`
        );
      }
      return minuteSlots;
    },
    minuteSlotsSecond() {
      const selectedHour = this.selectedHours[1].split(":")[0];
      const minutes = 55;
      const minuteSlots = [];
      for (let i = 0; i <= minutes; i += 5) {
        minuteSlots.push(
          String(i).length < 2
            ? `${selectedHour}:0${i}`
            : `${selectedHour}:${String(i)}`
        );
      }
      return minuteSlots;
    }
  },
  methods: {
    isSelected(hour) {
      const hourSplit = hour.split(":")[0];
      const start =
        this.selectedHours[0] && this.selectedHours[0].split(":")[0];
      const end = this.selectedHours[1] && this.selectedHours[1].split(":")[0];
      return hourSplit === start || hourSplit === end;
    },
    isInRange(hour) {
      if (this.selectedHours[0] && this.selectedHours[1]) {
        const num = Number(hour.split(":")[0]);
        const start = Number(this.selectedHours[0].split(":")[0]);
        const end = Number(this.selectedHours[1].split(":")[0]);
        return num >= start && num <= end;
      }
    },
    isStartOfTheRange(hour) {
      return (
        !this.selectedHours[0] ||
        (this.selectedHours[0] &&
          Number(hour.split(":")[0]) <=
            Number(this.selectedHours[0].split(":")[0]))
      );
    },
    isBeforeStartOfTheRange(hour) {
      return (
        !this.selectedHours[0] ||
        (this.selectedHours[0] &&
          Number(hour.split(":")[0]) <
            Number(this.selectedHours[0].split(":")[0]))
      );
    },
    isEndOfTheRange(hour) {
      return (
        this.selectedHours[1] &&
        Number(hour.split(":")[0]) ===
          Number(this.selectedHours[1].split(":")[0])
      );
    },
    selectHour(hour) {
      const hours = [...this.selectedHours];
      if (!hours[0] && !hours[1]) {
        this.firstHourSelected = true;
        hours[0] = hour;
      } else if (hours[0] && !hours[1]) {
        if (this.isBeforeStartOfTheRange(hour)) {
          this.firstHourSelected = true;
          hours[1] = hours[0];
          hours[0] = hour;
        } else {
          hours[1] = hour;
        }
      } else {
        hours[0] = hour;
        hours[1] = null;
      }

      this.selectHourFn(hours);
    },
    selectMinute(minute, from) {
      if (from) {
        this.selectHourFn([minute, this.selectedHours[1]]);
      } else {
        this.selectHourFn([this.selectedHours[0], minute]);
      }
    },
    displayTime(time) {
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;

      if (timeFormat == null) {
        timeFormat = this.timeFormat;
      }
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  },
  props: {
    selectHourFn: {
      type: Function,
      required: true
    },
    selectedHours: {
      type: Array,
      required: false
    },
    rangeMarked: {
      default: true,
      type: Boolean,
      required: false
    },
    timeFormat: {
      type: String,
      required: false
    }
  }
};
</script>
